<template>
  <CollectionCard>
    <CollectionCardImage :on-chain-count="onChainCount" :off-chain-count="offChainCount" :image="item.image" :is-off-chain="item.isOffChain" v-bind="$attrs">
      <template #back>
        <div class="collection-card-utility-description">{{ item.description }}</div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader :name="item.name" :guild="item.guild" />

    <CollectionCardActions
      :items="actions"
      :is-off-chain="item.isOffChain"
      v-bind="$attrs"
      @resurrectTeen="resurrectTeen"
      @mint="initAction('mint')"
      @moveOffChain="initAction('move-off-chain')"
      @send="sendItem"
      v-if="!isMinting"
    />
    <CollectionCardSelectCount
      @cancel="cancel"
      :max="actionType === 'mint' ? offChainCount : onChainCount"
      :is-loading="isLoading"
      :approve-text="actionType === 'mint' ? 'Mint' : 'Approve'"
      @approve="approveAction"
      v-if="isMinting"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref} from "vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWeb3Store } from "@/store/web3";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { mintOffChainUtilities } from "@/functions/mintOffChainUtilities";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";

export default defineComponent({
  components: { CollectionCardActions, CollectionCardSelectCount, CollectionCardImage, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const walletCollectionStore = useWalletCollectionStore()

    const offChainCount = computed(() => props.item.offChainCount)
    const onChainCount = computed(() => props.item.onChainCount)

    const actions = computed(() => {
      const items = [
        // {
        //   name: "Resurrect Teen",
        //   event: "resurrectTeen",
        //   disabled: walletCollectionStore.gods.findIndex(god => god.guild === props.item.guild) < 0 ||
        //     walletCollectionStore.teens.findIndex(teen => !teen.isResurrected && teen.guild === props.item.guild) < 0
        // }
      ]
      // if (props.item.offChainCount > 0) {
      //   items.push(
      //     {
      //       name: "Mint",
      //       event: "mint"
      //     }
      //   )
      // }
      if (props.item.offChainCount > 0) {
        items.push({
          name: "Transfer",
          event: "send"
        })
      }
      if (props.item.onChainCount > 0) {
        items.push(
          {
            name: "Move off-chain",
            event: "moveOffChain"
          }
        )
      }
      
      return items
    })
    const sendItem = () => {
      useModal().open({
        name: ModalName.SendUtilityModal,
        props: {
          item: props.item,
          maxAmount: 1
        }
      })
    }
    const resurrectTeen = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.ressurrection, {
        utilityToken: props.item
      })
    }

    const isLoading = ref(false)
    const actionType = ref('')
    const isMinting = computed(() => !!actionType.value)
    
    const initAction = async (type) => {
      actionType.value = type
    }
    const cancel = () => {
      actionType.value = ''
    }

    const approveAction = async (count) => {
      isLoading.value = true
      const data = [
          {
              id: props.item.id,
              amount: count
          }
      ]
      let success;
      if (actionType.value === 'mint') {
          success = await mintOffChainUtilities(data)
      } else {
          success = await moveUtilitiesOffChain(data)
      }
      isLoading.value = false
      if (success) {
          actionType.value = ''
      }
      setTimeout(() => {
          useWalletCollectionStore().fetchCollection(true)
      }, 500)
    }

    return { TokenType, actions, sendItem, resurrectTeen, isLoading, actionType, approveAction, cancel, initAction, isMinting, offChainCount, onChainCount }
  }
})
</script>
<style lang="scss">
</style>